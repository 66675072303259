/* Profile.module.css */
@keyframes grow-shrink {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .iconAnimate {
    animation: grow-shrink 0.5s ease-in-out;
  }